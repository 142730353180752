import router from '../../router';
import Apis from '../../services/Apis';


export default {
    namespaced: true,
    state: {
        isLoggedin: false,
        token: null,
        registeredUser: {
            firstName: "",
            lastName: "",
            gender: "",
            role: "",
            phoneNumber: "",
            nationalId: "",
            institutionName: "",
            clusterName: "",
        },
        verificationCode: null,
        selectedUser: null,
        moreDetails: null
    },
    mutations: {
        setUser: (state, { projectRoles,user}) => {
            state.user = {...user, projectRoles}
        },
        logoutRequest: (state) => {
            state.isLoggedin = false;
            state.token = null;
            state.user = null;
            state.registeredUser = {
                firstName: "",
                lastName: "",
                gender: "",
                role: "",
                phoneNumber: "",
                nationalId: "",
                institutionName: "",
                clusterName: "",
            }
        },
        loginSuccess: (state, result) => {
            state.isLoggedin = true;
            state.token = result.accessToken;
            // state.user = result.user
        },
        loginFailure: (state) => {
            state.isLoggedin = false;
        },
        setSelectedUser: (state, selectedUser) => state.user = selectedUser,
        setVerificationState: (state, vercode) => state.verificationCode = vercode,
    },
    getters: {
        email(state) {
            return state.user.email
        },
        user(state) {
            return state.user
        },
        id(state) {
            return state.user ? state.user.id : undefined
        },
        token(state) {
            return state.token
        },
        backendUrl() {
            return process.env.VUE_APP_api_service_url;
        },
        isLoggedin(state) {
            return state.isLoggedin
        }
    },
    actions: {

        async signup({ state }, user) {
            const result = await Apis.create(`users/register`, {
                email: user.email,
                firstName: state.registeredUser.firstName,
                gender: state.registeredUser.gender,
                lastName: state.registeredUser.lastName,
                mobile: state.registeredUser.mobile,
                nationalId: state.registeredUser.nationalId,
                password: user.password,
                role: state.registeredUser.role,
                clusterName: state.registeredUser.clusterName,
                institutionName: state.registeredUser.institutionName
            })

            if (result) {
                state.registeredUser = {
                    firstName: "",
                    lastName: "",
                    gender: "",
                    role: "",
                    phoneNumber: "",
                    nationalId: "",
                    institutionName: "",
                    clusterName: "",
                }
                router.push('/all-users')
            }
        },
        async getDetails({ state }) {
            const result = await Apis.get(`users/moreDetails`)

            state.moreDetails = result.data.data
        },
        async otpcode({ commit, state }, otpcode) {
            const result = await Apis.otpcode({ email: state.user.email, otp: otpcode, password: state.user.password })
            if (result) {
                commit('loginSuccess', result.data)
                router.push('/')
            }
        },
        async initialReset({ commit }, email) {
            const result = await Apis.initialReset({ emaill: email })
            if (result) {
                commit('setUser', { email })
                router.push('/verify')
            }
        },
        async resetPass({ state }, password) {
            const result = await Apis.reset({ activationCode: state.verificationCode, email: state.user.email, password })
            if (result) {
                router.push('/login')
            }
        },

        async updateUser({ state }, { user, id }) {
            await Apis.update(`users`, id, user)
                .then((res) => {
                    if ([200, 201].includes(res.status))
                        router.push('/')
                })
                .catch((error) => state.errors = error.data)
        },
        verifycode({ commit }, vercode) {
            commit('setVerificationState', vercode)
            router.push('/createpass')
        },
        setUser({ state }, user) {
            state.registeredUser = user
            router.push('/create-account/user-credential')
        },
        logout({ commit }) {
            commit('logoutRequest')
            router.push('/login')
        },
    },
}
