import Api from "./index";
import store from "../store";

/* Axios interceptors */
Api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    if (error.response) {
      if (
        error.response.data.status == 401 &&
        error.response.data.error == "Unauthorized"
      ) {
        if (store.getters["auth/isLoggedin"]) {
          store.dispatch("auth/logout");
        } else {
          store.dispatch("notification/SET_NOTIFICATION", {
            msg: error.response.data.error,
            uptime: 3000,
            hasError: true,
          });
        }
      }
      if (error.response.data.apierror) {
        if (error.response.data.apierror.message != "Settings not yet uploaded")
          store.dispatch("notification/SET_NOTIFICATION", {
            msg: error.response.data.apierror.message,
            title: error.response.data.apierror.details,
            uptime: 3000,
            hasError: true,
          });
      } else {
        store.dispatch("notification/SET_NOTIFICATION", {
          msg: error.response.data.message,
          title: error.response.data.error,
          uptime: 3000,
          hasError: true,
        });
      }
    } else {
      store.dispatch("notification/SET_NOTIFICATION", {
        msg: error.message,
        uptime: 3000,
      });
    }
  }
);

Api.interceptors.request.use(function (config) {
  const token = `Bearer ${store.getters["auth/token"]}`;
  config.headers.Authorization = token;

  return config;
});

export default {
  get(url, headers) {
    return Api.get(url, headers);
  },
  create(path, body) {
    return Api.post(`/${path}`, body);
  },
  signup(body) {
    if (body != null) return Api.post(`/auth/signup`, body);
  },
  update(path, id, body) {
    return Api.put(`/${path}/${id}`, body);
  },
  configurable_update(path, id, body, config) {
    return Api.put(`/${path}/${id}`, body, config);
  },
  updateStatus(path) {
    return Api.put(`/${path}`);
  },
  login(body) {
    if (body != null) return Api.post(`/auth/signin`, body);
  },
  otpcode(body) {
    if (body != null) return Api.post(`/auth/verifyOtp`, body);
  },
  initialReset(body) {
    if (body != null) return Api.post(`/auth/initiate-reset-password`, body);
  },
  delete(url) {
    return Api.delete(url);
  },
  reset(body) {
    if (body != null) return Api.post(`/auth/reset-password`, body);
  },
  createAppointment(body) {
    if (body != null) return Api.post(`/appointment`, body);
  },
  // createAppointee(body, appointmentId) {
  //     if (body != null) return Api.post(`/appointee/${appointmentId}`, body)
  // },
  sendProfile(body, appointmentId) {
    if (body != null)
      return Api.post(`/appointee/profile/${appointmentId}`, body);
  },
  sendCV(cv, appointmentId) {
    if (cv != null) return Api.post(`/appointee/cv/${appointmentId}`, cv);
  },
};
