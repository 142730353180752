import router from "../../router";
import Apis from "../../services/Apis";

const initialState = {
  appointment: {
    title: "",
    description: "",
    address: "",
    date: new Date().toISOString().substr(0, 10),
    time: "",
  },
  appointee: {
    names: "",
    email: "",
    address: "",
    title: "",
    mobile: "",
    nationalId: "",
    description: "",
    age: "",
    newPosition: "",
    position: "",
  },
  appointmentId: null,
  appointeeId: null,
  selectedAppointment: null,
  selectedAppointee: null,
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setAppointment: (state, newAppointment) => {
      state.appointment = newAppointment;
      if (newAppointment.redirect) router.push(`/create-appointment/userdetails${!newAppointment.clear ? '' :'?cached=true'}`);
    },
    setAppointee: (state, newAppointee) => {
      state.appointee = newAppointee;
      if (newAppointee.redirect) router.push(`/create-appointment/usereducation${!newAppointee.clear ? '' :'?cached=true'}`);
    },
    setSelectedAppointment: (state, selectedAppointment) =>
      (state.selectedAppointment = selectedAppointment),
    setSelectedAppointee: (state, selectedAppointee) =>
      (state.selectedAppointee = selectedAppointee),
    // getAppointmentID: (state, appointmentId) => state.appointmentId = appointmentId.data.data.id,
    //
    getAppointeeID: (state, appointeeId) =>
      (state.appointeeId = appointeeId.data.data.appointee.id),

    updateAppointment: (state, newAppointment) => {
      state.appointment = newAppointment;
      router.push(`/update-appointment/${state.appointment.id}/userdetails`);
    },
    updateAppointee: (state, newAppointee) => {
      state.appointee = newAppointee;
      router.push(`/update-appointment/${state.appointment.id}/usereducation`);
    },
  },
  actions: {
    // async createAppointment({commit}, newAppointment){
    //     const result = await Apis.createAppointment(newAppointment)
    //     commit("getAppointmentID" , result)
    //     if(result){
    //         commit('setAppointment', newAppointment)
    //         router.push('/create-appointment/userdetails')
    //     }
    // },
    async createAppointment({ commit, state }, { imageUrl, cvPath }) {
      const result = await Apis.create("appointment", {
        address: state.appointment.address,
        appointee: {
          email: state.appointee.email,
          location: state.appointee.address,
          mobile: state.appointee.mobile,
          names: state.appointee.names,
          title: state.appointee.title,
          description: state.appointee.description,
          nationalId: state.appointee.nationalId,
          age: state.appointee.age,
          position: state.appointee.position,
          newPosition: state.appointee.newPosition,
        },
        description: state.appointment.description,
        date: state.appointment.date,
        title: state.appointment.title,
      });
      commit("getAppointeeID", result);
      if (imageUrl && typeof imageUrl == "object") {
        const profileformData = new FormData();
        profileformData.append("file", imageUrl);
        await Apis.sendProfile(profileformData, state.appointeeId)
          .then(() => console.log("profile uploaded successfully"))
          .catch((err) => console.error(err));
      }

      if (cvPath && typeof cvPath == "object") {
        console.log("Image", cvPath);
        const cvformData = new FormData();
        cvformData.append("file", cvPath);
        await Apis.sendCV(cvformData, state.appointeeId)
          .then(() => console.log("cv uploaded successfully"))
          .catch((err) => console.error(err));
      }
      if (result) {
        state = initialState;
        router.push("/appointments");
      }
    },

    //Update
    async updateAppointment(
      { commit, state },
      { nationalId, imageUrl, description, cvPath }
    ) {
      const result = await Apis.update(
        "appointment",
        state.appointment.id + "/" + state.appointee.id,
        {
          address: state.appointment.address,
          appointee: {
            description,
            email: state.appointee.email,
            location: state.appointee.location,
            mobile: state.appointee.mobile,
            names: state.appointee.names,
            nationalId,
            title: state.appointee.title,
            age: state.appointee.age,
            newPosition: state.appointee.newPosition,
            position: state.appointee.position,
          },
          description: state.appointment.description,
          date: state.appointment.date,
          title: state.appointment.title,
          status: state.appointment.status,
        }
      );
      commit("getAppointeeID", result);
      if (imageUrl && typeof imageUrl == "object") {
        const profileformData = new FormData();
        profileformData.append("file", imageUrl);
        await Apis.update("appointee", state.appointeeId, profileformData)
          .then(() => console.log("profile uploaded successfully"))
          .catch((err) => console.error(err));
      }
      if (cvPath && typeof cvPath == "object") {
        const cvformData = new FormData();
        cvformData.append("file", cvPath);
        await Apis.sendCV(cvformData, state.appointeeId)
          .then(() => console.log("cv uploaded successfully"))
          .catch((err) => console.error(err));
      }
      router.push("/appointments");
    },
  },
  getters: {},
};
