import Vue from "vue";
import VueRouter from "vue-router";
// import store from '../store/modules/auth'
import store from "../store";

import Apis from "../services/Apis";

Vue.use(VueRouter);

const routes = [
  {
    path: "/test",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/dashboard/index.vue"
      ),
    children: [
      {
        path: "/",
        meta: {
          protected: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "index" */ "../views/dashboard/Dashboard.vue"
          ),
      },
      {
        path: "/create-appointment/appointmentdetails",
        name: "AppointmentDetails",
        meta: {
          protected: true,
          permited_users: ["SECRETARY", "VIEWER"],
        },
        component: () =>
          import(
            /* webpackChunkName: "create-appointment" */ "../views/Forms/Create-Appointment/FirstStep.vue"
          ),
      },
      {
        path: "/create-appointment/userdetails",
        name: "GeneralDetails",
        meta: {
          protected: true,
          permited_users: ["SECRETARY", "VIEWER"],
        },
        component: () =>
          import(
            /* webpackChunkName: "create-appointment" */ "../views/Forms/Create-Appointment/SecondStep.vue"
          ),
      },
      {
        path: "/create-appointment/usereducation",
        name: "EducationBackground",
        meta: {
          protected: true,
          permited_users: ["SECRETARY", "VIEWER"],
        },
        component: () =>
          import(
            /* webpackChunkName: "create-appointment" */ "../views/Forms/Create-Appointment/ThirdStep.vue"
          ),
      },
      {
        path: "/update-appointment/:id/appointmentdetails",
        meta: {
          protected: true,
          permited_users: ["SECRETARY", "VIEWER"],
        },
        component: () =>
          import(
            /* webpackChunkName: "appointment" */ "../views/Forms/Update-Appointment/FirstStep.vue"
          ),
      },
      {
        path: "/update-appointment/:id/userdetails",
        meta: {
          protected: true,
          permited_users: ["SECRETARY", "VIEWER"],
        },
        component: () =>
          import(
            /* webpackChunkName: "appointment" */ "../views/Forms/Update-Appointment/SecondStep.vue"
          ),
      },
      {
        path: "/update-appointment/:id/usereducation",
        meta: {
          protected: true,
          permited_users: ["SECRETARY", "VIEWER"],
        },
        component: () =>
          import(
            /* webpackChunkName: "update-appointment" */ "../views/Forms/Update-Appointment/ThirdStep.vue"
          ),
      },
      {
        path: "/update-account/:id/user-identify",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Forms/update-account/Identification.vue"
          ),
      },
      {
        path: "/update-account/:id/user-credential",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Forms/update-account/Credentials.vue"
          ),
      },
      {
        path: "/appointments",
        name: "List Appointment",
        meta: {
          protected: true,
          permited_users: ["SECRETARY", "VIEWER"],
        },
        component: () => import("../views/Listings/AppointmentListing.vue"),
      },
      {
        path: "/appointment-reports",
        name: "Appointment reports",
        meta: {
          protected: true,
          permited_users: ["SECRETARY", "VIEWER"],
        },
        component: () => import("../views/Listings/AppointmentReport.vue"),
      },

      {
        path: "/log-reports",
        name: "Cabinet Log reports",
        meta: {
          protected: true,
          permited_users: ["SECRETARY", "VIEWER"],
        },
        component: () => import("../views/Listings/CabinetLogReport.vue"),
      },
      // document routes
      {
        path: "/documents",
        name: "Document Details",
        meta: {
          protected: true,
          permited_users: ["CLUSTER_USER", "VIEWER", "CLUSTER_ADMIN"],
        },
        component: () => import("../views/Listings/DocumentDetails.vue"),
      },

      {
        path: "/ministry-documents",
        name: "Ministry Document Details",
        meta: {
          protected: true,
          permited_users: ["CLUSTER_ADMIN", "MINISTRY"],
        },
        component: () =>
          import("../views/Listings/MinistryDocumentDetails.vue"),
      },

      {
        path: "/gazette-processsing-documents",
        name: "Gazette Processing Document Details",
        meta: {
          protected: true,
          permited_users: ["GAZETTE_PROCESSOR"],
        },
        component: () =>
          import("../views/Listings/GazetteProcessingDocumentDetails.vue"),
      },

      {
        path: "/documents/:id/timeline",
        meta: {
          protected: true,
          permited_users: ["CLUSTER_USER", "VIEWER", "CLUSTER_ADMIN"],
        },
        component: () =>
          import(
            /* webpackChunkName: "minutes" */ "../views/DocumentHistory.vue"
          ),
      },

      {
        path: "/projects/:type",
        name: "Projects",
        meta: {
          protected: true,
          // permited_users: ["CLUSTER_USER", "VIEWER", "CLUSTER_ADMIN", "ADMIN"],
        },
        component: () => import("../views/Listings/Projects.vue"),
      },
      {
        path: "/users-project-roles",
        name: "User Projects Roles",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () => import("../views/Admin/UserListingProjectRoles.vue"),
      },
      {
        path: "/sectors",
        name: "Sectors",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () => import("../views/Admin/UserListingSector.vue"),
      },
      {
        path: "/appointments/:id/minutes",
        meta: {
          protected: true,
          permited_users: ["SECRETARY", "VIEWER"],
        },
        component: () =>
          import(/* webpackChunkName: "minutes" */ "../views/Minutes.vue"),
      },
      {
        path: "/documents/:id/edit",
        name: "DocumentTabs",
        meta: {
          protected: true,
          permited_users: ["CLUSTER_USER", "CLUSTER_ADMIN"],
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Forms/Document/AllTabs.vue"
          ),
      },
      {
        path: "/documents/new",
        name: "Create Document",
        meta: {
          protected: true,
          permited_users: ["CLUSTER_ADMIN", "MINISTRY"],
        },
        component: () => import("../views/Forms/Document/UploadNew.vue"),
      },
      {
        path: "/institution",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Forms/admin-setups/Institution.vue"
          ),
      },
      {
        path: "/cluster",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Forms/admin-setups/cluster.vue"
          ),
      },
      {
        path: "/update-institution/:id",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Forms/Admin-updates/Institution.vue"
          ),
      },
      {
        path: "/update-cluster/:id",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Forms/Admin-updates/cluster.vue"
          ),
      },
      {
        path: "/create-account/user-identify",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Forms/create-account/Identification.vue"
          ),
      },
      {
        path: "/create-account/user-credential",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Forms/create-account/Credentials.vue"
          ),
      },
      {
        path: "/all-users",
        name: "Admin Panel Users",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () => import("../views/Admin/UserListing.vue"),
      },
      {
        path: "/all-clusters",
        name: "All clusters",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () => import("../views/Admin/ClusterListing.vue"),
      },
      {
        path: "/settings",
        name: "System Settings",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () => import("../views/Admin/Settings.vue"),
      },
      {
        path: "/all-ministries",
        name: "Admin Panel View Ministires",
        meta: {
          protected: true,
          permited_users: ["ADMIN"],
        },
        component: () => import("../views/Admin/MinistriesListing.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login.vue"),
  },
  // {
  //   path: "/otp",
  //   name: "Verify code",
  //   component: () =>
  //     import(/* webpackChunkName: "otp" */ "../views/auth/OTPform.vue"),
  // },
  {
    path: "/reset",
    name: "Reset password",
    component: () =>
      import(
        /* webpackChunkName: "reset_password" */ "../views/resetPass/ResetPassForm.vue"
      ),
  },
  {
    path: "/verify",
    name: "Verify password code",
    component: () =>
      import(
        /* webpackChunkName: "verify_password" */ "../views/resetPass/VerifyPassForm.vue"
      ),
  },
  {
    path: "/createpass",
    name: "Create new password",
    component: () =>
      import(
        /* webpackChunkName: "create_pass" */ "../views/resetPass/CreateNewPass.vue"
      ),
  },
  {
    // catch all 404 - define at the very end
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  hash: false,
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isLoggedin = store.getters["auth/isLoggedin"];
  const id = store.getters["auth/id"];

  if (isLoggedin && !id) {
    const { data } = await Apis.get("/users/current/");
    // keep the decoded user in vuex store
    store.commit("auth/setUser", data.data);
  }
  const openRoutes = ["/login", "/reset", "/verify", "/createpass"];

  // check if the destination route is protected
  if (to.meta.protected && !isLoggedin) {
    // go to login
    next({
      path: "/login",
      // after logging in redirect to the requested route
      query: {
        redirect: to.fullPath,
      },
    });
  }
  // if user navigates to open routes while logged in
  else if (openRoutes.includes(to.path) && isLoggedin) {
    const resolved = router.resolve(to.path);
    if (resolved.name == "404") {
      next();
    } else {
      next({
        path: `/`,
      });
    }
  } else {
    const user = await store.state.auth.user;
    if (to.meta.permited_users) {
      // if (user.roles[0].name == "MINISTRY" && to.path != "/ministry-documents")
      //   next({ path: "/ministry-documents" });
      // else 
      if (to.meta.permited_users.includes(user.roles[0].name)) {
        next();
      } else {
        next({ path: "/access_denied" });
      }
    } else {
      next();
    }
  }
});

export default router;
