<template>
  <div id="notification" v-show="visible">
    <div class="icon">
      <img v-if="hasError" src="@/assets/icons/error.svg" alt="Error" />
      <img v-else src="@/assets/icons/check.svg" alt="Ok" />
    </div>
    <div class="next-action">{{ msg }}</div>
    <div class="message">{{ title }}</div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "NewNotification",
  computed: {
    ...mapGetters("notification", ["show", "msg", "title", "hasError"]),
    visible() {
      return this.show;
    },
    ...mapMutations({ closeNotification: "notification/RESET_NOTIFICATION" }),
  },
};
</script>

<style lang="scss">
#notification {
  position: absolute;
  width: 265px;
  height: 111px;
  right: 10px;
  bottom: 25px;
  z-index: 999;
  background: #252626;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  .icon {
    img {
      height: 23px;
      width: 33px;
      margin: 17px auto;
      border-radius: 0px;
    }
    margin-bottom: 11px;
  }
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  color: white;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  .message {
    font-weight: 500;
    margin-bottom: 5px;
  }
  .next-action {
    font-weight: 700;
  }
}
</style>