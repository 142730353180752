import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/scss/index.scss'
import "/src/assets/scss/tailwind.css"
import { getFormatedDate, timeAgo } from './functions'
import VueHtmlToPaper from 'vue-html-to-paper';



Vue.config.productionTip = false



const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],

    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}



Vue.filter('computeText', function (value) {
    if (!value) return;
    return value[0].toUpperCase();
})
Vue.filter('formatDate', function (date_time, type) {
    if (!date_time) return
    if (date_time.length) {
        date_time = new Date(`${date_time[0]}-${date_time[1] <= 9 ? '0' : ''}${date_time[1]}-${date_time[2] <= 9 ? '0' : ''}${date_time[2]}T${date_time[3] <= 9 ? '0' : ''}${date_time[3]}:${date_time[4] <= 9 ? '0' : ''}${date_time[4]}:00`)
    }

    const dateFormat = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"

    }); // format to format the date
    const formatedDate = dateFormat.formatToParts(new Date(date_time)); //lets format date

    return type == 'date' ? `${formatedDate[2].value} ${formatedDate[0].value} ${formatedDate[4].value}` : `${formatedDate[6].value}:${formatedDate[8].value} ${formatedDate[10].value}`;
})
Vue.filter('getTimeDifference', function (date1, date2 = new Date()) {
    let result

    // make sure they are date objects
    if (typeof date1 !== 'object') {
        date1 = new Date(date1)
    }
    if (typeof date1 !== 'object') {
        date2 = new Date(date2)
    }

    const diff = (date2.getTime() - date1.getTime());

    // To calculate the no. of days between two dates
    const Difference_In_Days = Math.round(diff / (1000 * 3600 * 24))

    if (Difference_In_Days > 1) {
        result = date1.toISOString().substr(2, 8).split('-').reverse().join('/')
    } else if (Difference_In_Days === 1) {
        result = 'yesterday'
    } else {
        result = date1.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    }

    return result
})
Vue.filter('getFormattedDate', getFormatedDate)
Vue.filter('timeAgo', timeAgo)
Vue.use(VueHtmlToPaper, options);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')