export default {
    namespaced: true,
    state: {
        visible: true,
    },
    mutations: {
        TOOGLE_SIDEBAR_VISIBILITY(state) {
            state.visible = !state.visible
        }
    },
    getters: {
        visible(state) {
            return state.visible
        }
    }
}
