import Vue from 'vue'
import Vuex from 'vuex'
import sidebar from "./modules/sidebar";
import auth from "./modules/auth";
import appointment from "./modules/appointment";
import document from "./modules/document";
import notification from "./modules/notification";
import admin from "./modules/admin";
import settings from "./modules/settings";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    sidebar,
    auth,
    document,
    appointment,
    notification,
    admin,
    settings
  },
  plugins: [createPersistedState()],
})
