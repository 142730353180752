export default {
    namespaced: true,
    state: {
        institution: null,
        cluster: null,
        selectedCluster: null,
        selectedInstitution: null
    },
    mutations: {
        setSelectedCluster: (state, selectedCluster) => state.selectedCluster = selectedCluster,
        setSelectedInstitution: (state, selectedInstitution) => state.selectedInstitution = selectedInstitution
    },
    actions: {
    },
    getters: {
        institution(state) {
            return state.institution
        },
        cluster(state) {
            return state.cluster
        }
    }
}
