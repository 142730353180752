
import Apis from "../../services/Apis"

export default {
    namespaced: true,
    state: {
        document: null,
        institution: null,
    },
    mutations: {
    },
    actions: {
        async createDocument( newDoc) {
            await Apis.create(`documents`, newDoc)
                .then((res) => {
                    console.log(res)

                })
                .catch((err) => console.log(err))
        },
    },
    getters: {
    }
}
