import Api from "../../services/Apis";
import router from "../../router";

export default {
  namespaced: true,
  state: {
    data: null,
  },
  mutations: {
    setSettings: (state, settings) => (state.data = settings),
  },
  actions: {
    async getSettings({ state }) {
      if (state.data) state.data = null;
      Api.get("settings")
        .then((res) => {
          state.data = res.data.data;
        })
        .catch(() =>
          fetch("/files/systemSettings.json")
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              state.data = data;
            })
            .catch((err) => {
              console.log(err);
            })
        );
    },
    async saveSettings({ state, dispatch }, data) {
      Api.create("settings", data).then((res) => {
        state.data = data;
        dispatch(
          "notification/SET_NOTIFICATION",
          {
            msg: res.data.message,
            uptime: 3000,
          },
          { root: true }
        );
        router.push("/");
      });
    },
    async getAllowedFields({ state }, target) {
      while (!state.data) {
        await new Promise((r) => setTimeout(r, 2000));
      }
      const fields = state.data[target];
      return JSON.parse(JSON.stringify(fields));
    },
  },
  getters: {
    settings(state) {
      return state.data;
    },
  },
};
