export default {
    namespaced: true,
    state: {
        show: false,
        title: "",
        msg: "",
        hasError: false
    },
    mutations: {
        RESET_NOTIFICATION(state) {
            state.show = false
            state.title = ""
            state.msg = ""
        }
    },
    actions: {
        SET_NOTIFICATION({ state, commit }, { title, subtitle, msg, uptime, hasError }) {
            state.show = true
            state.title = title
            state.subtitle = subtitle
            state.msg = msg
            state.hasError = hasError
            setTimeout(() => {
                commit('RESET_NOTIFICATION')
            }, uptime);
        },
    },
    getters: {
        show(state) {
            return state.show
        },
        title(state) {
            return state.title
        },
        msg(state) {
            return state.msg
        },
        uptime(state) {
            return state.uptime
        },
        hasError(state) {
            return state.hasError
        }
    }
}
